<template>
  <div class="login">
    <div class="bg">
      <van-image :src="require('../../assets/imgs/logo.png')" />
    </div>

    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="username"
        label="用户名"
        label-width="50"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        label-width="50"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 30px;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { postLoginApi } from '@/api'
export default {
  name: 'RepairLogin',
  data() {
    return {
      username: '',
      password: '',
      isBack: false // 登录成功后是否返回上个路由
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('to', to)
    console.log('from', from)
    // this.isBack = !(from.path === '/' || from.path === '/home');
    next()
  },
  methods: {
    onSubmit(params) {
      postLoginApi(params).then((res) => {
        console.log('维修员登录', res)
        if (res.code !== 0) return
        window.localStorage.setItem('user_info', JSON.stringify(res.data))
        window.localStorage.removeItem('sh_info')
        this.$toast({
          type: 'success',
          message: '登录成功！',
          onClose: () => {
            if (this.isBack) {
              this.$router.go(-1)
            } else {
              this.$router.push('/repair/helper')
            }
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  .bg {
    overflow: hidden;
    height: 400px;
    width: 100%;
    position: relative;
    /deep/ .van-image {
      z-index: 1;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translate(-50%);
      width: 80px;
      height: 80px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 4000px;
      height: 4000px;
      border-radius: 50%;
      background-color: #333;
    }
  }

  /deep/ .van-form {
    margin-top: 50px;
  }
}
</style>